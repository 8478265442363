<template>
  <div id="app" class="body">
    <router-view />
    <van-overlay :show="isLoading" :custom-style="maskClass">
      <van-loading color="#F60"  size="50px"/>
    </van-overlay>
  </div>
</template>
<script>

export default {
  name: 'app',
  data() {
    return {
      maskClass: {
        'display': 'flex',
        'justify-content': 'center',
        'align-items': 'center',
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.LoadingStore.isLoading;
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;

  // font-family: -apple-system, Helvetica, Hiragino Sans GB W3, Microsoft YaHei, arial, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
}

.body {

  color: #2c3e50;
  width: 100%;
  max-width: 540px;
  background-color: #f9f9f9;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  text-align: left;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

body {
  // height: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
}

.font-weight-700 {
  font-weight: 700;
}

.price-f {
  color: #f60;
  font-size: 0.9rem;
}

.price {
  color: #f60;
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 0.25rem;
}
</style>
