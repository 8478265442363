// 结算 选择 订购人 和 收件人 相关
export const AddressStore = {
    namespaced: true,
    state: () => ({
        isShowCheckedBtn: false, // 订购人列表 和 收件人列表 显示勾选按钮
        checkedSubscriber: null, // 选中的订购人
        checkedRecipient: null, // 选中的收件人
    }),
    mutations: {
        // 设置 订购人列表 和 收件人列表 显示/隐藏 勾选按钮
        setShowCheckedBtn(state, val) {
            state.isShowCheckedBtn = val;
        },
        // 设置订购人
        setSubscriber(state, val) {
            state.checkedSubscriber = val;
        },
        // 设置收件人
        setRecipient(state, val) {
            state.checkedRecipient = val;
        },
        // 结算初始化
        checkoutInit(state) {
            state.isShowCheckedBtn = true;
            state.checkedSubscriber = null;
            state.checkedRecipient = null;
        }
    },
    actions: {},
    getters: {}
}