
export const TOKEN_OBJ_KEY = 'TOKEN_OBJ';

// 搜索历史
export const KEY_SEARCH_HIST = "search-history";

export default class localStorageUtils {

    static setObj(key, val) {
        localStorage.setItem(key, JSON.stringify(val));
    }

    static getObj(key) {
        const strObj = localStorage.getItem(key);
        if (!strObj) {
            return null;

        }
        return JSON.parse(strObj);
    }

    static remove(key) {
        localStorage.removeItem(key);
    }

    static setToken(valObj) {
        this.setObj(TOKEN_OBJ_KEY, valObj);
    }

    static getToken() {
        return this.getObj(TOKEN_OBJ_KEY);
    }

    static removeToken(){
        return this.remove(TOKEN_OBJ_KEY);
    }

    // 获取搜索历史
    static getSearchHistory() {
        return this.getObj(KEY_SEARCH_HIST) || [];
    }
    // 存储搜索历史
    static setSearchHistory(historyList) {
        this.setObj(KEY_SEARCH_HIST, historyList);
    }
    // 清空搜索历史
    static removeSearchHistory() {
        this.remove(KEY_SEARCH_HIST);
    }
}