import { api } from "@/constants/api";
import { BaseService } from "./BaseService";

export default class ShoppingCartService extends BaseService {

    /** 购物车 - 商品列表 */
    static list() {
        return this.request({
            url: api.SHOPPING_CART_LIST
        });
    }

    /** 购物车 - 添加商品 */
    static pushGoods(_data) {
        return this.request({
            url: api.SHOPPING_CART_PUSH_GOODS,
            method: 'post',
            data: _data
        });
    }

    /** 购物车 - 修改商品数量 */
    static updateQuantity(_data) {
        return this.request({
            url: api.SHOPPING_CART_UPDATE_QUANTITY,
            method: 'post',
            data: _data
        });
    }

    /** 购物车 - 修改商品选中 */
    static check(_data) {
        return this.request({
            url: api.SHOPPING_CART_CHECK,
            method: 'post',
            data: _data
        });
    }

    /** 购物车 - 修改商品选中 */
    static checkAll(_data) {
        return this.request({
            url: api.SHOPPING_CART_CHECK_ALL,
            method: 'get',
            params: {
                checked: _data
            }
        });
    }

    /** 购物车 - 删除商品 */
    static delGoods(_data) {
        return this.request({
            url: api.SHOPPING_CART_DEL_GOODS,
            method: 'post',
            data: _data
        });
    }

    /** 购物车 - 统计勾选的商品数量 */
    static contChecked() {
        return this.request({
            url: api.SHOPPING_CART_CONT_CHECKED,
            method: 'get',
        });
    }
}