import axios from "axios";

export class BaseService {
  static request({ method, url, data, params }) {
    return axios({
      method: method,
      url: url,
      data: data,
      params: params
    }).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch((error) => {
      return Promise.reject(error);
    });
  }

  static async requestAsync({ method, url, data, params }) {
    return axios({
      method: method,
      url: url,
      data: data,
      params: params
    })
  }
}
