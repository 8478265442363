import ShoppingCartService from "@/service/ShoppingCartService"

// 搜索历史
export const ShoppingCartStore = {
    namespaced: true,
    state: () => ({
        checkedNum: 2, // 购物车选择商品数量
    }),
    mutations: {
        // 修改购物车选择商品数量
        setCheckedNum(state, n) {
            state.checkedNum = n;
        }
    },
    actions: {
        
        // 查询购物车勾选商品数量
        queryCheckedNum(context) {
            ShoppingCartService.contChecked()
                .then(response => {
                    console.log('queryCheckedNum response : ', response);
                    context.commit('setCheckedNum', response)
                }).catch(err => {
                    console.log('queryCheckedNum err : ', err);
                })
        },
    },
    getters: {}
}