import axios from "axios";
import TokenService from '@/service/TokenService'

axios.defaults.headers = {
    "Content-type": "application/json"
}

axios.defaults.timeout = 15000;

// Add a request interceptor
axios.interceptors.request.use(async function (config) {
    // Do something before request is sent
    console.log('axios.interceptors.request...');
    console.log(config.url);
    await TokenService.verifyToken(config); // 校验token

    console.log('拦截器 config : ', config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    console.log('axios.interceptors.response...');
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (!response || !response.data) {
        throw '接口异常';
    }
    const _data = response.data;
    if (_data.status == '0') {
        let _msg = _data.errMsg ? _data.errMsg : '接口返回异常';
        throw _msg;
    }
    if (_data.status == '456') { // token过期

    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    return Promise.reject('系统异常');
});