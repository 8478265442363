import { api } from "@/constants/api";
import { BaseService } from "./BaseService";
import localStorageUtils from "@/utils/localStorageUtils";
import router from '@/router'

/**
 * 受限资源；
 * 请求拦截器判断受限资源调用时，是否存在x-auth-token，不存在跳转登录页
 * 响应拦截器 监听响应 为401时，跳转登录页
 * 
 */
export const RESTRICTED_URL = [
    api.CUS_INFO,

    api.SHOPPING_CART_LIST,
    api.SHOPPING_CART_PUSH_GOODS,
    api.SHOPPING_CART_UPDATE_QUANTITY,
    api.SHOPPING_CART_CHECK,
    api.SHOPPING_CART_CHECK_ALL,
    api.SHOPPING_CART_DEL_GOODS,
    api.SHOPPING_CART_CONT_CHECKED,

    api.ADDRESS_LIST,
    api.ADDRESS_GET_BY_ID,
    api.ADDRESS_ADD,
    api.ADDRESS_UPDATE,
    api.ADDRESS_DEL,
    api.ADDRESS_SENDER_LIST,
    api.ADDRESS_SENDER_GET_BY_ID,
    api.ADDRESS_SENDER_ADD,
    api.ADDRESS_SENDER_UPDATE,
    api.ADDRESS_SENDER_DEL,

    api.ORDER_CHECKOUT,
    api.ORDER_CREATE,
    api.ORDER_LIST,
    api.ORDER_DETAIL,
    api.ORDER_CANCEL,

    api.PAYMENT_REQUEST,
    api.PAYMENT_VALIDATE,
    api.PAYMENT_GET_JSSDK_CONFIG,

];

/** 非强制受限（可受限/可不受限） */
export const OR_RESTRICTED_URL = [
    api.GOODS_LIST,
    api.GOODS_DETAIL,
    api.GOODS_HOME_FIELD,
];


export default class TokenService extends BaseService {

    // 是否刷新中
    static IS_REFRESHING = false;

    /** 根据uuid获取 */
    static uuidGetByJwtDto(_uuid) {
        return this.request({
            url: api.CUS_UUID_GET_JWT,
            params: {
                uuid: _uuid
            }
        });
    }

    static async verifyToken(config) {
        console.log(config.url);

        // 受限接口
        if (-1 != RESTRICTED_URL.indexOf(config.url)) {
            console.log('RESTRICTED_URL.indexOf....');
            try {
                await this.verifyTokenExpire(); // 校验token是否存在，是否过期，如果过期，刷新token
                const _token = localStorageUtils.getToken();
                config.headers = {
                    "x-auth-token": _token.accessToken
                }
            } catch (error) {
                if (!this.IS_REFRESHING) { // 确保token不是在刷新中的状态
                    router.push('/login'); // 跳转
                }
            }
        }
        // 非强制受限 接口
        if (-1 != OR_RESTRICTED_URL.indexOf(config.url)) {
            console.log('OR_RESTRICTED_URL.indexOf....');

            try {
                await this.verifyTokenExpire(); // 校验token是否存在，是否过期，如果过期，刷新token
                const _token = localStorageUtils.getToken();
                config.headers = {
                    "x-auth-token": _token.accessToken
                }
            } catch (error) {
                console.log('OR_RESTRICTED_URL token expire', error);
            }
        }

    }

    /** 校验token是否过期 */
    static async verifyTokenExpire() {
        console.log('verifyTokenExpire...');
        let token = localStorageUtils.getToken();
        console.log('token...', token);
        // 校验token是否存在
        if (null == token
            || !token.expireTime
            || !token.accessToken
            || !token.refreshToken) {
            throw 'token不存在';
        }
        console.log('verifyTokenExpire 过期校验...');
        // 校验是否过期，【token.expireTime - 10秒】 是否大于 【当前时间】，如果大于说明没有过期
        if (token.expireTime - (10 * 1000) > new Date().getTime()) {
            return
        }
        console.log('verifyTokenExpire 过期...');


        // 过期了，刷新token
        await this.onRefreshToken(token.refreshToken);
    }

    static async onRefreshToken(_refreshToken) {
        console.log('verifyTokenExpire 刷新...');

        if (this.IS_REFRESHING) {
            return; // 正在刷新中
        }
        this.IS_REFRESHING = true;
        /** 根据uuid获取 */
        // this.request({
        //     url: api.CUS_REFRESH_TOKEN,
        //     params: {
        //         key: _refreshToken
        //     }
        // }).then(response => {
        //     console.log('verifyTokenExpire 刷新结果...');
        //     localStorageUtils.setToken(response);
        // }).catch((errMsg) => {
        //     throw errMsg;
        // }).finally(() => {
        //     this.IS_REFRESHING = false;
        // });

        try {
            let response = await this.requestAsync({
                url: api.CUS_REFRESH_TOKEN,
                params: {
                    key: _refreshToken
                }
            });
            console.log('verifyTokenExpire 刷新结果...');
            localStorageUtils.setToken(response.data.data);
        } finally {
            this.IS_REFRESHING = false;
        }
    }
}