import Vue from 'vue'
import Vuex from 'vuex'
import { LoadingStore } from './modules/LoadingStore'
import { ShoppingCartStore } from './modules/ShoppingCartStore'
import { AddressStore } from './modules/AddressStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    LoadingStore,
    ShoppingCartStore,
    AddressStore,
  }
})
